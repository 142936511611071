import StorageService from '@/support/services/storage'
import WebsocketService from '@/support/services/websocket'
import DataService from '@/support/services/data'

export default {
    namespaced: true,
    state: () => ({
        current: null,
        endpoints: [],
        paused: false,
        requests: [],
        ignoredRequests: 0,
        selectedRequest: null
    }),
    getters: {
        current(state) {
            const endpointIndex = DataService.getIndex({
                array: state.endpoints,
                data: {id: state.current}
            })

            return state.endpoints[endpointIndex]
        },
        endpoints(state) {
            return state.endpoints
        },
        ignoredRequests(state) {
            return state.ignoredRequests
        },
        paused(state) {
            return state.paused
        },
        requests(state) {
            return state.requests
        },
        selectedRequest(state) {
            return state.selectedRequest
        }
    },
    actions: {
        deleteRequest({state, dispatch, commit}, {request}) {
            commit('DELETE_REQUEST', request)

            if (state.selectedRequest && request.id === state.selectedRequest.id)
                dispatch('selectRequest', {request: null})
        },
        incrementIgnoredRequests({commit}) {
            commit('INCREMENT_IGNORED_REQUESTS')
        },
        async insertEndpoint({state, dispatch, commit}, {endpoint, current = true}) {
            await StorageService.local.get('endpoints')
                .then(async (endpoints) => await StorageService.local.set('endpoints', [endpoint, ...endpoints]))
                .catch(async () => await StorageService.local.set('endpoints', [endpoint]))
                .finally(() => {
                    commit('INSERT_ENDPOINT', endpoint)

                    if (current)
                        dispatch('setCurrent', endpoint.id)
                })
        },
        insertRequest({state, commit}, {request}) {
            commit('INSERT_REQUEST', request)
        },
        resetRequests({dispatch, commit}) {
            commit('RESET_REQUESTS')

            dispatch('selectRequest', {request: null})
        },
        async setCurrent({state, dispatch, commit}, endpointId) {
            if (state.current) {
                WebsocketService.socket()
                    .emit('leave', `endpoint.${state.current}`)
            }

            await StorageService.local.set('current_endpoint', endpointId)

            dispatch('resetRequests')

            WebsocketService.socket()
                .emit('join', `endpoint.${endpointId}`)
                .on('request_received', ({request}) => state.paused ?
                    dispatch('incrementIgnoredRequests') :
                    dispatch('insertRequest', {request})
                )

            commit('SET_CURRENT', endpointId)
        },
        selectRequest({commit}, {request}) {
            commit('SELECT_REQUEST', request)
        },
        switchPaused({state, commit}) {
            commit('SWITCH_PAUSED')

            if (state.paused)
                commit('RESET_IGNORED_REQUESTS')
        }
    },
    mutations: {
        DELETE_REQUEST(state, request) {
            DataService.delete({
                array: state.requests,
                data: request
            })
        },
        INCREMENT_IGNORED_REQUESTS(state) {
            state.ignoredRequests = state.ignoredRequests + 1
        },
        INSERT_ENDPOINT(state, endpoint) {
            DataService.insert({
                array: state.endpoints,
                data: endpoint
            })
        },
        INSERT_REQUEST(state, request) {
            DataService.insert({
                array: state.requests,
                data: request
            })
        },
        RESET_IGNORED_REQUESTS(state) {
            state.ignoredRequests = 0
        },
        RESET_REQUESTS(state) {
            state.requests = []
        },
        SELECT_REQUEST(state, request) {
            state.selectedRequest = request
        },
        SET_CURRENT(state, id) {
            state.current = id
        },
        SWITCH_PAUSED(state) {
            state.paused = !state.paused
        }
    }
}