import axios from 'axios'

const HttpService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL
    },
    get(url, config) {
        return axios.get(url, config)
    },
    post(url, data, config) {
        return axios.post(url, data, config)
    },
    put(url, data, config) {
        return axios.put(url, data, config)
    }
}

export default HttpService
