import {io} from 'socket.io-client'

let socket = null

const WebsocketService = {
    init() {
        const settings = process.env.VUE_APP_ENVIRONMENT === 'production' ?
            {
                path: '/ws/',
                transports: ['websocket']
            } : {}

        socket = io(process.env.VUE_APP_WS_HOST, settings)
    },
    socket() {
        return socket
    }
}

export default WebsocketService